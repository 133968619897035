<template>
  <div>
    <team-page></team-page>
  </div>
</template>

<script>
import TeamPage from '@/components/TeamPage.vue';

export default {
  name: "Team",
  data() {
    return {
      //
    };
  },
  components: {TeamPage},
};
</script>
