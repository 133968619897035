<template>
  <div class="mt-5">
    <h2 class="text-center theme-text text-uppercase" title="Uocharcoal, the team behind, best charcoal in nigeria">
      Our Team
    </h2>
    <br>
    <v-row>
      <v-col cols="12" sm="6" lg="3"
        v-for="image in images"
        :key="image.id"
        :title="image.alt"
      >
        <v-card>
          <v-img            
            contain
            :src="image.src"
            :alt="image.alt"
            :title="image.alt"
          >
          </v-img>
          <v-card-subtitle>
            <h2 class="text-center">
              {{ image.name }}
            </h2>
            <h3 class="text-center theme-text">
              {{ image.position }}
            </h3>
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import Vue from "vue";
import { uuid } from '@/services'

export default {
  name: 'TeamPage',
  data() {
    return {
      images: [
        {
          id: uuid(),
          src: "uploads/team1.jpg",
          name: "Oruese Emagbetere",
          position: "CEO/Visionary",
          alt: "Oruese Emagbetere, CEO/Visionary - Uocharcoal, best of nigeria charocal"
        },
        {
          id: uuid(),
          src: "uploads/team2.jpg",
          name: "Stanley Nnoromele",
          position: "International Operations",
          alt: "Stanley Nnoromele, International Operations - Uocharcoal, best of nigeria charocal"
        },
        {
          id: uuid(),
          src: "uploads/team3.jpg",
          name: "Atase Jennifer Adjarho",
          position: "Logistics",
          alt: "Stanley Nnoromele, Logistics - Uocharcoal, best of nigeria charocal"
        },
        {
          id: uuid(),
          src: "uploads/team4.jpg",
          name: "Godwin Oghenenyerhovwo",
          position: "Quality Control Expert",
          alt: "Stanley Nnoromele, Quality Control Expert - Uocharcoal, best of nigeria charocal"
        },
      ],
      
    };
  },
}
</script>